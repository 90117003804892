import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import { Button, Dropdown } from 'react-bootstrap';
import { Sliders, ZoomIn, ZoomOut } from 'react-feather';
import { RootState } from '../../model/slices/root/root.state';
import { actions } from '../../model/slices/app/app.slice';
import { MAX_FONT_SCALE, MIN_FONT_SCALE } from '../../model/constants';

const FONT_SCALE_CHANGE_STEP = 5;

const FontScaleNavItem = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fontScale = useSelector((state: RootState) => state.app.fontScale);

  const setFontScale = (newFontScale: number) => {
    const validScale = Math.max(Math.min(newFontScale, MAX_FONT_SCALE), MIN_FONT_SCALE);
    dispatch(actions.setFontScale(validScale));
  };

  return (
    <>
      <style jsx global>{`
        :root {
          --ayd-font-scale: ${fontScale};
        }
      `}</style>
      <Dropdown className="me-2 nav-item" align="end">
        <Dropdown.Toggle as="a" className="nav-icon nav-link">
          <Sliders className="align-middle" size={18} />
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu">
          <div className="px-2">
            <div className="text-center">{t('FONT_SCALE_NAV_ITEM.LABEL')}</div>
            <hr className="my-1" />
            <div className="d-flex flex-row align-items-center">
              <Button
                variant="link"
                size="sm"
                onClick={() => setFontScale(fontScale - FONT_SCALE_CHANGE_STEP)}
              >
                <ZoomOut className="align-middle" size={18} />
              </Button>
              <span className="flex-grow-1 text-center">{fontScale}%</span>
              <Button
                variant="link"
                size="sm"
                onClick={() => setFontScale(fontScale + FONT_SCALE_CHANGE_STEP)}
              >
                <ZoomIn className="align-middle" size={18} />
              </Button>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default FontScaleNavItem;
