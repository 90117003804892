import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import useApiClient from './use-api-client';
import MeService, { UpdateMeRequestBody } from '../api/services/me.service';
import { ME_QUERY_KEY } from './use-me-query';
import { MyUser } from '../types/my-user';

export interface UseUpdateMeMutationOptions {
  optimisticUpdatesEnabled: boolean;
}

const DEFAULT_OPTIONS: UseUpdateMeMutationOptions = {
  optimisticUpdatesEnabled: true,
};

const useUpdateMeMutation = (
  options: UseUpdateMeMutationOptions = DEFAULT_OPTIONS
): UseMutationResult<MyUser, unknown, UpdateMeRequestBody> => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const { optimisticUpdatesEnabled } = options;

  return useMutation(data => new MeService(apiClient).updateMe(data), {
    onMutate: async data => {
      if (!optimisticUpdatesEnabled) {
        return undefined;
      }
      await queryClient.cancelQueries({ queryKey: ME_QUERY_KEY });
      const savedUser = queryClient.getQueryData<MyUser>(ME_QUERY_KEY);
      if (savedUser) {
        const updatedUser: MyUser = {
          ...savedUser,
          ...data,
        };
        queryClient.setQueryData<MyUser>(ME_QUERY_KEY, updatedUser);
      }
      return savedUser;
    },
    onError: (err, data, previousValue: MyUser | undefined) => {
      if (previousValue) {
        queryClient.setQueryData<MyUser>(ME_QUERY_KEY, previousValue);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(ME_QUERY_KEY).catch(() => {});
    },
  });
};

export default useUpdateMeMutation;
