import React from 'react';
import { useRouter } from 'next/router';
import { Navbar, Nav, Col, Row, Container, Dropdown } from 'react-bootstrap';
import 'react-image-lightbox/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import { connect, ConnectedProps } from 'react-redux';
import { Settings, User } from 'react-feather';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { io, Socket } from 'socket.io-client';
import Sidebar from '../components/sidebar/sidebar.component';
import { RootState } from '../model/slices/root/root.state';
import { actions } from '../model/slices/app/app.slice';
import useMeQuery from '../model/hooks/use-me-query';
import { clientConfig } from '../model/config';
import SocketContext from '../components/socket-context/socket-context';
import setReactDatepickerLocale from '../utils/set-react-datepicker-locale/set-react-datepicker-locale';
import FontScaleNavItem from '../components/font-scale-nav-item/font-scale-nav-item.component';
import PageMeta from '../components/page-meta/page-meta.component';
import Loader from '../components/loader/loader.component';
import LocaleNavItem from '../components/locale-nav-item/locale-nav-item.component';

const mapStateToProps = (state: RootState) => ({
  isBoxed: state.app.isBoxed,
  sidebarIsOpen: state.app.sidebarIsOpen,
  loaderOverlayVisible: state.app.loaderOverlayVisible,
});

const mapDispatchToProps = {
  toggleSidebarIsOpen: actions.toggleSidebarIsOpen,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type DashboardProps = { children: React.ReactNode } & ConnectedProps<typeof connector>;

const Dashboard: React.FC<DashboardProps> = ({
  children,
  isBoxed,
  toggleSidebarIsOpen,
  loaderOverlayVisible,
}) => {
  const { t, i18n } = useTranslation();

  const router = useRouter();

  const [socket, setSocket] = React.useState<Socket | null>(null);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const newSocket = io(clientConfig.apiUrl, {
        withCredentials: true,
      });
      setSocket(newSocket);
      return () => {
        newSocket.close();
      };
    }
    return () => {};
  }, []);

  React.useEffect(() => {
    setReactDatepickerLocale(i18n.resolvedLanguage);
  }, [i18n.resolvedLanguage]);

  const { data: user } = useMeQuery();

  return (
    <SocketContext.Provider value={socket}>
      <div className={`wrapper ${isBoxed ? 'wrapper-boxed' : ''}`}>
        <PageMeta title={t('DASHBOARD_LAYOUT.META.TITLE')} />
        <Sidebar pathName={router.pathname} />
        <div className="main">
          <Navbar variant="light" expand className="navbar-bg">
            <span className="sidebar-toggle d-flex ms-4" onClick={() => toggleSidebarIsOpen()}>
              <i className="hamburger align-self-center" />
            </span>
            <Navbar.Collapse>
              <Nav className="navbar-align align-items-center">
                <FontScaleNavItem />
                <LocaleNavItem />
                <Dropdown className="nav-item" align="end">
                  <span className="d-inline-block d-sm-none">
                    <Dropdown.Toggle as="a" className="nav-link">
                      <Settings size={18} className="align-middle" />
                    </Dropdown.Toggle>
                  </span>
                  <span className="d-none d-sm-inline-block">
                    <Dropdown.Toggle as="a" className="nav-link">
                      {user && (
                        <>
                          <img
                            src={user.profilePictureUrl || '/img/user-avatar-placeholder.svg'}
                            className="avatar img-fluid rounded-circle me-1"
                            alt={user.fullName}
                          />
                          <span className="text-dark">{user.fullName}</span>
                        </>
                      )}
                    </Dropdown.Toggle>
                  </span>
                  <Dropdown.Menu>
                    <Link href="/my-account" passHref>
                      <Dropdown.Item>
                        <User size={18} className="align-middle me-2" />
                        {t('NAVBAR.MY_ACCOUNT')}
                      </Dropdown.Item>
                    </Link>
                    <Dropdown.Divider />
                    <Link href="/logout" passHref>
                      <Dropdown.Item>{t('NAVBAR.LOGOUT')}</Dropdown.Item>
                    </Link>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <div className="content">{children}</div>
          {loaderOverlayVisible && (
            <div className="page-overlay" style={{ opacity: 0.8 }}>
              <Loader />
            </div>
          )}
          <footer className="footer">
            <Container fluid>
              <Row className="text-muted">
                <Col xs="12" className="text-end">
                  <p className="mb-0">
                    &copy; {new Date().getFullYear()} - <span className="text-muted">Ayd</span>
                  </p>
                </Col>
              </Row>
            </Container>
          </footer>
          <div className="react-table-portal" style={{ zIndex: 9999 }} />
        </div>
      </div>
    </SocketContext.Provider>
  );
};

export default connector(Dashboard);
