import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Dropdown } from 'react-bootstrap';
import useUpdateMeMutation from '../../model/hooks/use-update-me-mutation';
import useMeQuery from '../../model/hooks/use-me-query';
import getLocalizedPath from '../../utils/get-localized-path/get-localized-path';

interface LanguageOption {
  locale: string;
  icon: string;
  name: string;
}

const LANGUAGE_OPTIONS: LanguageOption[] = [
  {
    locale: 'en',
    icon: '/img/flags/us.png',
    name: 'English',
  },
  {
    locale: 'sv',
    icon: '/img/flags/se.png',
    name: 'Svenska',
  },
];

const LocaleNavItem: React.FC<{}> = () => {
  const router = useRouter();
  const { asPath, defaultLocale } = router;
  const { i18n } = useTranslation();

  const { data: user } = useMeQuery();
  const { mutateAsync: saveUser } = useUpdateMeMutation({
    // Prevent the language change from being applied immediately as it causes a page
    // reload before the request has a chance to update the language on the server
    optimisticUpdatesEnabled: false,
  });

  const changePreferredLanguage = (locale: string) => {
    const newPath = getLocalizedPath({ path: asPath, defaultLocale, locale });
    if (user) {
      saveUser({
        preferences: {
          ...user.preferences,
          language: locale,
        },
      })
        .then(() => window.location.replace(newPath))
        .catch(() => {});
    } else {
      window.location.replace(newPath);
    }
  };

  const currentLanguageOption = React.useMemo(
    () =>
      LANGUAGE_OPTIONS.find(option => option.locale === i18n.resolvedLanguage) ||
      LANGUAGE_OPTIONS[0],
    [i18n]
  );

  const sortedLanguageOptions = React.useMemo(
    () => [
      currentLanguageOption,
      ...LANGUAGE_OPTIONS.filter(option => option.locale !== currentLanguageOption.locale),
    ],
    [currentLanguageOption]
  );

  return (
    <Dropdown className="me-2 nav-item" align="end">
      <Dropdown.Toggle as="a" className="nav-link nav-flag">
        <img src={currentLanguageOption.icon} alt={currentLanguageOption.name} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {sortedLanguageOptions.map(({ locale, icon, name }) => (
          <Dropdown.Item key={locale} onClick={() => changePreferredLanguage(locale)}>
            <img src={icon} alt={name} width="20" className="align-middle me-1" />
            <span className="align-middle">{name}</span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LocaleNavItem;
