import React from 'react';
import Link from 'next/link';
import {
  List as ListIcon,
  Home,
  Users,
  BookOpen,
  Settings,
  Activity,
  FileText,
  Slack,
  Eye,
  Clipboard,
  Type as TypeIcon,
} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'next-i18next';
import { RootState } from '../../model/slices/root/root.state';
import SidebarItem from '../sidebar-item/sidebar-item.component';
import SidebarCategory from '../sidebar-category/sidebar-category.component';
import { Action, ProtectedResource } from '../../model/entities/role.entity';
import Gate from '../gate/gate.component';

const mapStateToProps = (state: RootState) => ({
  sidebarIsOpen: state.app.sidebarIsOpen,
  sidebarIsSticky: state.app.sidebarIsSticky,
});

const connector = connect(mapStateToProps);

type SidebarProps = ConnectedProps<typeof connector> & { pathName: string };

const Sidebar: React.FC<SidebarProps> = ({ pathName, sidebarIsOpen, sidebarIsSticky }) => {
  const { t } = useTranslation();

  return (
    <nav
      className={`sidebar${!sidebarIsOpen ? ' collapsed' : ''}${
        sidebarIsSticky ? ' sidebar-sticky' : ''
      }`}
    >
      <div className="sidebar-content">
        <PerfectScrollbar>
          <Link href="/">
            <a className="sidebar-brand">
              <img src="/img/logo-white.svg" width="80px" />
            </a>
          </Link>
          <ul className="sidebar-nav">
            <SidebarItem name={t('SIDEBAR.MENU.HOME')} to="/" icon={Home} pathName={pathName} />
            <Gate config={[ProtectedResource.CaseReport, Action.List]}>
              <SidebarItem
                name={t('SIDEBAR.MENU.CASE_REPORTS')}
                to="/case-reports"
                icon={ListIcon}
                pathName={pathName}
              />
            </Gate>
            <Gate config={[ProtectedResource.Order, Action.List]}>
              <SidebarItem
                name={t('SIDEBAR.MENU.ORDERS')}
                to="/orders"
                icon={Clipboard}
                pathName={pathName}
              />
            </Gate>
            <Gate config={[ProtectedResource.Ticket, Action.List]}>
              <SidebarItem
                name={t('SIDEBAR.MENU.TICKETS')}
                to="/tickets"
                icon={Slack}
                pathName={pathName}
              />
            </Gate>
            <Gate config={[ProtectedResource.User, Action.List]}>
              <SidebarItem
                name={t('SIDEBAR.MENU.USERS')}
                to="/users"
                icon={Users}
                pathName={pathName}
              />
            </Gate>
            <Gate config={[ProtectedResource.AnonymizedCaseReport, Action.List]}>
              <SidebarCategory name={t('SIDEBAR.MENU.AYD_ANALYTICS')} icon={Activity} isOpen>
                <SidebarItem
                  name={t('SIDEBAR.MENU.AYD_ANALYTICS.CASE_REPORTS_CHART')}
                  to="/ayd-analytics/case-reports-chart"
                  pathName={pathName}
                />
                <SidebarItem
                  name={t('SIDEBAR.MENU.AYD_ANALYTICS.PIVOT_TABLE')}
                  to="/ayd-analytics/pivot-table"
                  pathName={pathName}
                />
                <SidebarItem
                  name={t('SIDEBAR.MENU.AYD_ANALYTICS.FOLLOW_UPS_CHART')}
                  to="/ayd-analytics/follow-ups-chart"
                  pathName={pathName}
                />
              </SidebarCategory>
            </Gate>
            <Gate
              config={[
                [ProtectedResource.AccessToken, Action.Create],
                [ProtectedResource.AccessToken, Action.Update],
              ]}
            >
              <SidebarItem
                name={t('SIDEBAR.MENU.DATA_EXPORT')}
                to="/data-export"
                icon={FileText}
                pathName={pathName}
              />
            </Gate>
            <Gate config={[ProtectedResource.AuditLog, Action.List]}>
              <SidebarItem
                name={t('SIDEBAR.MENU.AUDIT_LOG')}
                to="/audit-log"
                icon={BookOpen}
                pathName={pathName}
              />
            </Gate>
            <Gate config={[[ProtectedResource.TextTemplate, Action.List]]}>
              <SidebarItem
                name={t('SIDEBAR.MENU.TEXT_TEMPLATES')}
                to="/text-templates"
                icon={TypeIcon}
                pathName={pathName}
              />
            </Gate>
            <Gate config={[[ProtectedResource.Role, Action.List]]}>
              <SidebarItem
                name={t('SIDEBAR.MENU.ROLES')}
                to="/roles"
                icon={Eye}
                pathName={pathName}
              />
            </Gate>
            <Gate
              config={[
                [ProtectedResource.AccessToken, Action.Create],
                [ProtectedResource.AccessToken, Action.Update],
              ]}
            >
              <SidebarItem
                name={t('SIDEBAR.MENU.SETTINGS')}
                to="/settings"
                icon={Settings}
                pathName={pathName}
              />
            </Gate>
          </ul>
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default connector(Sidebar);
