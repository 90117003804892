import { MyUser } from '../../model/types/my-user';
import {
  getPermissionsFromConfig,
  PermissionConfig,
} from '../get-permissions-from-config/get-permissions-from-config';

const checkUserPermissions = (
  config: PermissionConfig | PermissionConfig[],
  user: MyUser
): boolean => {
  const requiredPermissions = getPermissionsFromConfig(config);
  return requiredPermissions.every(permission => user.permissions.includes(permission));
};

export default checkUserPermissions;
